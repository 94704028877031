import React from 'react'
import Section from '../components/Section'

const RulePage = () => {
  return (
    <>
      <Section title='QUY ĐỊNH NHÓM' />
      <div className='space-y-12 mb-6'>
        <div>
          <div>
            <strong className='text-main-color'>I. NGÀY THÀNH LẬP VÀ KẾ HOẠCH THU CHI</strong>
            <ul>
              <li>Tên nhóm: <strong>Ánh Sáng từ thiện</strong></li>
              <li>Ngày thành lập nhóm: 29-08-2010.</li>
              <li>
                Số tiền quyên góp Quỹ Từ Thiện tối thiểu là 60.000đ/người/tháng.
                Thông báo tài chính của nhóm sẽ báo qua mail ban quản lý.
                Tiền đóng trực tiếp cho Thủ Quỹ/ hoặc Tổ Trưởng chuyển về Trưởng
                Nhóm/ Thủ Quỹ.
              </li>
              <li>
                Thời gian quyên góp từ ngày 01 tây đến hết ngày 15 tây hàng
                tháng (quyên góp tiền hoặc kèm theo vật phẩm). Tổng hợp số liệu
                báo cáo tài chính hàng tháng vào ngày 20 tây hàng tháng.
              </li>
              <li>
                Số tiền đi từ thiện tùy thuộc vào từng hoàn cảnh mà Ban Quản Lý
                bàn bạc xem xét. tuỳ thuộc vào nguồn quỹ của nhóm sẽ chọn thời
                gian đi từ thiện.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <strong className='text-main-color'>II. HÌNH THỨC THAM GIA NHÓM</strong>
            <ul>
              <li>
                Khi có thành viên gia nhập vào nhóm: phải cung cấp các thông tin
                cá nhân (họ tên, số điện thoại, địa chỉ email,…) để Trưởng Nhóm
                và Trợ Lý cập nhật danh sách
              </li>
              <li>
                Nếu vì bất kỳ lý do gì không thể tiếp tục hoạt động với nhóm:
                phải gặp mặt các thành viên trong cuộc họp và nêu ra lý do chính
                đáng sẽ được các thành viên đồng ý. Trường hợp sau này nếu đủ
                điều kiện sẽ được hân hoan tiếp đón trở lại. Ngược lại, sẽ không
                được xem xét (thành viên tự ý rút khỏi nhóm).
              </li>
              <li>
                Nếu trong 06 tháng liên tục thành viên không đóng góp quỹ xem
                như tự ý rút khỏi nhóm, cắt tên ra khỏi danh sách nhóm.
              </li>
              <li>
                Áo nhóm chỉ mặc khi đi thăm hoàn cảnh &amp; các hoạt động phát
                quà từ thiện. Những thành viên nào sử dụng áo ngoài mục đích từ
                thiện (vụ lợi cá nhân, đi chơi … gây ảnh hưởng đến uy tín của
                Nhóm Ánh Sáng) thì sẽ bị thu lại áo (miễn hoàn tiền) &amp; loại
                bỏ ra khỏi nhóm.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <strong className='text-main-color'>III. MỤC ĐÍCH VÀ HÌNH THỨC HOẠT ĐỘNG</strong>
            <ul>
              <li>
                <strong>Mục đích:</strong> giúp đỡ và chia sẻ phần nào những khó
                khăn với các cụ già neo đơn. Phát quà cho học sinh nghèo hiếu
                học tại các trường
              </li>
              <li>
                <strong>Hình thức:</strong> trao trực tiếp bằng tiền và vật phẩm
                (không qua bất kỳ một tổ chức trung gian)
              </li>
              <li>
                Giúp đỡ các đối tượng theo thứ tự sau: Cụ già neo đơn &amp; trẻ
                em bất hạnh có nguy cơ bỏ học.
              </li>
              <li>
                Mọi người đến với nhóm phải bằng cái tâm, biết chia sẻ, thương
                người nghèo khổ và không một ai vì lợi cá nhân. Nếu có sự không
                minh bạch hoặc chia rẽ nhóm sẽ bị loại trừ khỏi nhóm ngay lập
                tức.
              </li>
              <li>
                Thông tin, dữ liệu và hình ảnh hoạt động của nhóm sẽ được cập
                nhật trên website theo địa chỉ sau:www.anhsangtuthien.com
              </li>
              <li>
                <strong>E-mail:</strong> nguyenthanhtan1985@ymail.com
              </li>
              <li>
                Nếu trong gia đình của thành viên nào có khó khăn gì thì báo
                ngay cho Trưởng Nhóm và chia sẽ cùng với các thành viên khác để
                tìm cách giúp đỡ
              </li>
              <li>
                Tham gia các buổi họp nhóm, chuyến đi từ thiện cùng với nhóm:Nếu
                thành viên nào bận việc không tham gia được cuộc họp thì nhắn
                tin báo cho Tổ Trưởng, Tổ Trưởng chuyển thông tin lên Trưởng
                Nhóm qua tin nhắn SMS hoặc email.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <strong className='text-main-color'>IV. KHEN THƯỞNG VÀ TẶNG QUÀ</strong>
            <ul>
              <li>Cuối năm sẽ tổng kết tuyên dương và tặng quà cho các thành viên, quản lý, tổ hoạt động tích cực nhất trong năm</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default RulePage
